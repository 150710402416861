import {Component} from '@angular/core';
import {Store} from "@ngrx/store";
import {logout} from "../../../store/auth/auth.actions";
import {getUser} from "../../../store/auth/auth.selector";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {AuthUserService} from "../../services/auth/auth-user.service";
import { ERole } from '../../../@shared/enum/role.enum';
import { DashboardFacade } from '../../../@shared/facade/dashboard.facade';

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrl: './sidenav.component.scss'
})
export class SidenavComponent {
	roleEnum = ERole;
	user$ = this.store.select(getUser);
  version = '0.0.1';
	constructor(private store: Store, private authUserService: AuthUserService, private router: Router, private dashboardFacade: DashboardFacade) {
	  this.version = environment.version
  }

	logout() {
    // this.authService.logout()
		this.store.dispatch(logout());
	}

  goToMyProfile(){
    this.router.navigate(['dashboard/users/' + this.authUserService.user._id]);
  }

  resetFilters() {
    this.dashboardFacade.setDocId(undefined);
    this.dashboardFacade.resetFilters();
  }
}
